<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <!-- <v-select
            v-model="packageFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="packagesFilters"
            :clearable="false"
            label="text"
            style="width:170px"
          /> -->

          <!-- <b-button variant="primary" @click="createPackage">
            <feather-icon icon="PlusIcon" size="16" />
            Nuevo paquete
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se encontraron registros"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :tbody-tr-class="rowClass"
    >
      <!-- Check package -->
      <template #cell(check)="data">
        <b-form-checkbox
          v-if="packageIsReady(data.item)"
          :value="{ package_id: data.item.id }"
          v-model="checkItems"
        />
      </template>

      <!-- client -->
      <template #cell(client_package)="data">
        {{
          data.item.client_package
            ? `${data.item.client_package.client.name} ${data.item.client_package.client.surname}`
            : 'S/C'
        }}
      </template>

      <!-- Medidas -->
      <template #cell(large)="data">
        <i
          class="fa fa-ruler-combined text-info"
          :id="`popover-target-${data.item.id}-${data.item.created_at}`"
        ></i>
        <b-popover
          :target="`popover-target-${data.item.id}-${data.item.created_at}`"
          triggers="hover"
          placement="top"
          style="z-index: 2000 !important"
        >
          <template #title>Medidas</template>
          <p><strong>Largo:</strong> {{ data.item.large }}</p>
          <p><strong>Alto:</strong> {{ data.item.height }}</p>
          <p><strong>Ancho:</strong> {{ data.item.width }}</p>
        </b-popover>
      </template>

      <!-- carrier_bag_package -->
      <!-- <template #cell(carrier_bag_package)="data">
        {{
          data.item.carrier_bag_package
            ? data.item.carrier_bag_package.carrier_bag.code
            : 'No'
        }}
      </template> -->
      <!-- created_at -->
      <template #cell(created_at)="data">
        {{ dateFormat(data.item.created_at) }}
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            @click="deletePackageFromCarrierBag(data.item.id)"
            icon="TrashIcon"
            size="16"
            class="text-danger"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted" v-if="totalRegisters > 0"
            >Mostrando {{ fromRegister }} a {{ toRegister }} de
            {{ totalRegisters }} registros
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="items.length !== totalRegisters"
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

      <!-- <div class="pt-1" v-if="checkItems.length > 0">
        <b-button @click="openAddPackages" variant="primary">
          <feather-icon icon="PlusIcon" size="16" />
          Agregar paquetes seleccionado a saca
        </b-button>
      </div> -->
    </div>

    <!-- Right sidebar -->
    <b-sidebar
      v-model="showPackageModal"
      id="sidebar-right"
      :title="(flagUpdateAction ? 'Actualizar ' : 'Nuevo ') + 'paquete'"
      right
      shadow
    >
      <div class="p-2">
        <!-- form -->
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(storePackage)">
            <!-- tracking -->
            <b-form-group label="Tracking" label-for="tracking">
              <ValidationProvider
                name="Tracking"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="tracking"
                  v-model="packageEB.tracking"
                  name="tracking"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- description -->
            <b-form-group label="Descripción" label-for="description">
              <ValidationProvider
                name="Descripción"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="description"
                  v-model="packageEB.description"
                  name="description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- large -->
            <b-form-group label="Largo" label-for="large">
              <ValidationProvider
                name="Largo"
                rules="required|double:2"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="large"
                  v-model="packageEB.large"
                  name="large"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- height -->
            <b-form-group label="Alto" label-for="height">
              <ValidationProvider
                name="Alto"
                rules="required|double:2"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="height"
                  v-model="packageEB.height"
                  name="height"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- width -->
            <b-form-group label="Ancho" label-for="width">
              <ValidationProvider
                name="Ancho"
                rules="required|double:2"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="width"
                  v-model="packageEB.width"
                  name="width"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- weight -->
            <b-form-group label="Peso(lb)" label-for="weight">
              <ValidationProvider
                name="Peso(lb)"
                rules="required|double:2"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="weight"
                  v-model="packageEB.weight"
                  name="weight"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- price -->
            <b-form-group label="Valor" label-for="price">
              <ValidationProvider
                name="Valor"
                rules="required|double:2"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="price"
                  v-model="packageEB.price"
                  name="price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- submit button -->
            <div class="text-center">
              <b-button type="submit" variant="primary">
                <feather-icon icon="SaveIcon" size="16" />
                Guardar
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-sidebar>

    <!-- Add packages to carrier bag sidebar -->
    <b-sidebar
      v-model="showAddPackagesModal"
      id="sidebar-right"
      title="Agregar paquetes a saca"
      right
      shadow
    >
      <div class="px-2">
        <p>
          Agrega los paquetes que seleccionaste a una de las sacas disponibles
        </p>
        <div>
          <v-select
            v-model="selectedCarrierBag"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="availableCarrierBags"
            :clearable="false"
            label="code"
          />
        </div>
        <div class="p-2 text-center">
          <b-button variant="primary" @click="storeCheckPackagesInCarrierBag">
            <feather-icon icon="SaveIcon" size="16" />
            Guardar
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- Add package to client modal -->
    <b-sidebar
      v-model="showAddPackageToClientModal"
      id="sidebar-right"
      title="Agrega un cliente al paquete"
      right
      shadow
    >
      <div class="p-2">
        <v-select
          label="name"
          :filterable="false"
          :options="clients"
          @search="onSearch"
          v-model="selectedClient"
        >
          <template slot="no-options">
            Escribe el nombre de un cliente
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">{{ option.name }} {{ option.surname }}</div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} {{ option.surname }}
            </div>
          </template>
        </v-select>

        <div class="p-2 text-center">
          <b-button variant="primary" @click="storeClientToPackage()">
            <feather-icon icon="SaveIcon" size="16" />
            Asociar
          </b-button>
        </div>
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  BForm,
  BFormSelect,
  BSidebar,
  BFormCheckbox,
  BPopover,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onMounted, ref, watch, computed } from '@vue/composition-api'
import useInvoicesList from '../views/apps/invoice/invoice-list/useInvoiceList'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'

//Services
import orderService from '@/services/order.service'
import clientService from '@/services/client.service'

//Helpers
import {
  confirmAlert,
  perPageBaseOptions,
  perPageBaseRegisters,
} from '@/helpers'
import moment from 'moment'
//import useVuelidate from '@vuelidate/core'
//import { required, height } from '@vuelidate/validators'

export default {
  props: {
    carrier_bag_id: {
      required: true,
    },
    returnPackageId: {
      default: false,
    },
    currentShowCarrierBagPackageId: {
      default: null,
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    BFormGroup,
    BForm,
    BFormSelect,
    BSidebar,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BPopover,
  },
  setup(props, { emit }) {
    const toast = useToast()
    //Data (properties)
    const loading = ref(true)
    const items = ref([])
    const tableColumns = ref([
      //   { key: 'check', label: '', sortable: false },
      { key: 'tracking', label: 'Tracking', sortable: true },
      { key: 'client_package', label: 'Cliente', sortable: true },
      { key: 'description', label: 'Descripción', sortable: true },
      {
        key: 'large',
        label: 'Medidas',
      },

      { key: 'weight', label: 'Peso(Lb)', sortable: true },
      { key: 'price', label: 'Valor', sortable: true },
      // { key: 'carrier_bag_package', label: 'Saca', sortable: true },
      { key: 'created_at', label: 'Fecha', sortable: true },
      { key: 'actions', label: 'Acciones' },
    ])

    const packagesFilters = ref([
      { text: 'Todos', value: 'all' },
      { text: 'Listos para saca', value: 'ready' },
      { text: 'Por identificar', value: 'pending' },
    ])

    const packageFilter = ref({ text: 'Todos', value: 'all' })
    const showAddPackagesModal = ref(false)

    const showPackageModal = ref(false)
    const flagUpdateAction = ref(false)

    const basePackage = {
      tracking: '',
      description: '',
      large: '',
      height: '',
      width: '',
      weight: '',
      price: '',
    }
    const checkItems = ref([])
    const packageEB = ref(JSON.parse(JSON.stringify(basePackage)))

    //Pagination
    const currentPage = ref(1)
    const lastPage = ref(1)
    const totalRegisters = ref(1)
    const search = ref('')
    const fromRegister = ref(1)
    const toRegister = ref(perPageBaseRegisters)
    const perPage = ref(perPageBaseRegisters)
    const perPageOptions = ref(perPageBaseOptions)
    const availableCarrierBags = ref([])
    const selectedCarrierBag = ref(null)
    const showAddPackageToClientModal = ref(false)
    const currentPackageToClientId = ref(null)
    const clients = ref([])
    const selectedClient = ref(null)

    //Methods
    const rowClass = (item, type) => {
      if (!item || type !== 'row') return
      console.log(props.currentShowCarrierBagPackageId, item.id)
      if (item.id == props.currentShowCarrierBagPackageId)
        return 'table-success'
    }
    const deletePackageFromCarrierBag = async package_id => {
      try {
        const confirm = await confirmAlert(
          'Eliminar paquete',
          'Vas a eliminar el paquete de la saca'
        )
        if (!confirm.isConfirmed) return

        const {
          data: res,
        } = await carrierBagService.deletePackageFromCarrierBag({
          package_id: package_id,
        })

        const index = items.value.findIndex(i => i.id === package_id)
        items.value.splice(index, 1)
        emit(
          'deletePackageFromCarrierBag',
          props.returnPackageId ? package_id : res.data.carrier_bag_id
        )
      } catch (error) {
        console.log(error)
      }
    }

    const dateFormat = (date, format = 'DD/MM/YYYY') => {
      return moment(date).format(format)
    }

    const createPackage = () => {
      packageEB.value = JSON.parse(JSON.stringify(basePackage))
      showPackageModal.value = true
      flagUpdateAction.value = false
    }
    const editPackage = item => {
      packageEB.value = JSON.parse(JSON.stringify(item))
      showPackageModal.value = true
      flagUpdateAction.value = true
    }

    const deletePackage = async packageEB_id => {
      try {
        const confirm = await confirmAlert(
          'Eliminar paquete',
          'Vas a eliminar el paquete deseas continuar'
        )
        if (!confirm.isConfirmed) return

        const { data: res } = await packageService.deletePackage({
          id: packageEB_id,
        })

        const index = items.value.findIndex(i => i.id === packageEB_id)
        items.value.splice(index, 1)
        //items.value = JSON.parse(JSON.stringify(items.value))
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }

    const storePackage = async () => {
      try {
        const { data: res } = await (flagUpdateAction.value
          ? packageService.updatePackage(packageEB.value)
          : packageService.storePackage(packageEB.value))

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
          return
        }

        showPackageModal.value = false
        //If store
        if (!flagUpdateAction.value) items.value.unshift(res.data)
        //If update
        if (flagUpdateAction.value) {
          const index = items.value.findIndex(i => i.id === res.data.id)
          items.value[index] = res.data
          items.value = JSON.parse(JSON.stringify(items.value))
        }

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.log(error)
      }
    }

    const get = async (page = 1, searchQuery = {}) => {
      loading.value = true
      searchQuery.perPage = perPage.value
      searchQuery.filter = packageFilter.value.value
      const { data: res } = await orderService.getPackagesByCarrierBag(
        page,
        searchQuery,
        props.carrier_bag_id
      )
      items.value = res.data.data
      loading.value = false
      //Pagination
      lastPage.value = res.data.last_page
      toRegister.value = res.data.to
      fromRegister.value = res.data.from

      totalRegisters.value = res.data.total
    }

    //Get available carrier bags
    const getAvailableCarrierBags = async () => {
      const { data: res } = await carrierBagService.getAvailableCarrierBags()
      availableCarrierBags.value = res.data
    }

    //Store checked packages in carrier bag
    const storeCheckPackagesInCarrierBag = async () => {
      try {
        const { data: res } = await packageService.storePackagesInCarrierBag({
          carrier_bag_id: selectedCarrierBag.value.id,
          packages: checkItems.value,
        })
        if (res.success) {
          //update row in table
          get()
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          showAddPackagesModal.value = false
          checkItems.value = []
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    const openAddPackages = () => {
      showAddPackagesModal.value = true
    }

    //Add client to package
    const addClientToPackage = package_id => {
      selectedClient.value = null
      currentPackageToClientId.value = package_id
      showAddPackageToClientModal.value = true
    }
    //Delete client to package
    const deleteClientToPackage = async client_package => {
      try {
        const confirm = await confirmAlert(
          'Desasociar paquete',
          'Vas a desasociar el paquete del cliente'
        )
        if (!confirm.isConfirmed) return

        const { data: res } = await packageService.deleteClientToPackage(
          client_package
        )

        if (res.success) {
          const index = items.value.findIndex(i => i.id === res.data.package_id)
          items.value[index].client_package = null
          items.value = JSON.parse(JSON.stringify(items.value))

          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: errorMessage,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Ajax search select client
    const onSearch = async (search, loading) => {
      try {
        const { data: res } = await clientService.getClients(1, {
          perPage: 10,
          term: search,
        })

        clients.value = res.data.data
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Store client to package asociar
    const storeClientToPackage = async () => {
      if (!currentPackageToClientId.value || !selectedClient.value) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Debes elegir un cliente para el paquete',
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
        return
      }

      try {
        const { data: res } = await packageService.storeClientToPackage({
          client_id: selectedClient.value.id,
          package_id: currentPackageToClientId.value,
        })
        const index = items.value.findIndex(i => i.id === res.data.package_id)
        items.value[index].client_package = res.data
        items.value = JSON.parse(JSON.stringify(items.value))
        showAddPackageToClientModal.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Package is ready for carrier bag
    const packageIsReady = p => {
      const isReady =
        !Boolean(p.carrier_bag_package) &&
        p.large &&
        p.height &&
        p.width &&
        p.price &&
        p.weight &&
        Boolean(p.client_package)

      return isReady
    }

    //Wachers
    watch(currentPage, (currentPage, prevCurrentPage) => {
      get(currentPage, {
        term: search.value,
      })
    })

    watch(perPage, currentPerPage => {
      toRegister.value = currentPerPage
      get(1, {
        term: search.value,
      })
    })

    watch(packageFilter, currentPackageFilter => {
      packageFilter.value = currentPackageFilter
      get(1, {
        term: search.value,
      })
    })

    watch(search, async (search, prevSearch) => {
      if (currentPage.value === 1) {
        await get(currentPage.value, {
          term: search,
        })
      } else {
        currentPage.value = 1
      }
    })

    //Mounted
    onMounted(() => {
      get()
      getAvailableCarrierBags()
    })

    const {
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolvePackageAvatarVariant,
    } = useInvoicesList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalRegisters,
      perPageOptions,
      search,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolvePackageAvatarVariant,
      items,
      fromRegister,
      toRegister,
      packageEB,
      storePackage,
      showPackageModal,
      editPackage,
      createPackage,
      deletePackage,
      flagUpdateAction,
      packagesFilters,
      packageFilter,
      checkItems,
      showAddPackagesModal,
      openAddPackages,
      availableCarrierBags,
      selectedCarrierBag,
      storeCheckPackagesInCarrierBag,
      addClientToPackage,
      showAddPackageToClientModal,
      clients,
      onSearch,
      selectedClient,
      storeClientToPackage,
      deleteClientToPackage,
      packageIsReady,
      dateFormat,
      deletePackageFromCarrierBag,
      rowClass,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
