<template>
  <span>
    <span v-if="packageEB">
      <i :id="packageEB.id" :class="'fa ' + iconClass"></i>
      <b-tooltip :target="`${packageEB.id}`" triggers="hover">
        {{ status ? status.status.name : 'S/E' }}
      </b-tooltip>
    </span>
  </span>
</template>
<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTooltip,
  },
  props: {
    status: {
      default: null,
    },
    packageEB: {
      default: null,
    },
  },
  computed: {
    iconClass() {
      let classes = 'fa-circle'
      console.log(this.status)
      if (this.status && this.status.status) {
        if (this.status.status.id === 1) {
          classes = 'fa-building text-info'
        } else if (this.status.status.id === 2) {
          classes = 'fa-box text-warning'
        } else if (this.status.status.id === 3) {
          classes = 'fa-plane-departure text-warning'
        } else if (this.status.status.id === 4) {
          classes = 'fa-plane-arrival text-success'
        } else if (this.status.status.id === 5) {
          classes = 'fa-truck text-danger'
        } else if (this.status.status.id === 6) {
          classes = 'fa-house-user text-primary'
        }
      }

      return classes
    },
  },
}
</script>
