<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <!-- <v-select
            v-model="packageFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="packagesFilters"
            :clearable="false"
            label="text"
            style="width:170px"
          /> -->
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se encontraron registros"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- client -->

      <!-- last package status -->
      <template #cell(last_package_status)="data">
        <status-package
          :status="data.item.last_package_status"
          :packageEB="data.item"
        ></status-package>
      </template>
      <!-- created_at -->
      <template #cell(created_at)="data">
        {{ dateFormat(data.item.created_at) }}
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            @click="showTimelinePackage(data.item)"
            icon="EyeIcon"
            size="16"
            class="mx-2 text-success"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted" v-if="totalRegisters > 0"
            >Mostrando {{ fromRegister }} a {{ toRegister }} de
            {{ totalRegisters }} registros
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="items.length !== totalRegisters"
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <!-- Right sidebar new package-->
    <b-sidebar
      v-model="showTimelinePackageModal"
      id="sidebar-right"
      title="Estado paquete"
      right
      shadow
    >
      <div class="p-2" v-if="currentPackage">
        <div class="p-0 m-0">
          <p><strong>Descripción:</strong> {{ currentPackage.description }}</p>
        </div>
        <hr />
        <div>
          <app-timeline>
            <app-timeline-item
              v-for="status in currentPackage.package_statuses"
              :key="status.id"
              :title="status.status.name"
              :subtitle="status.status.description"
              :time="dateFormat(status.created_at)"
              variant="success"
            />
          </app-timeline>
        </div>
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  BForm,
  BFormSelect,
  BSidebar,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onMounted, ref, watch, computed } from '@vue/composition-api'
import useInvoicesList from '../apps/invoice/invoice-list/useInvoiceList'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'

//Services
import orderService from '@/services/order.service'
import clientService from '@/services/client.service'

//Helpers
import { perPageBaseOptions, perPageBaseRegisters } from '@/helpers'
import moment from 'moment'

import PackagesInCarrierBag from '@/components/PackagesInCarrierBag.vue'
import StatusPackage from '@/components/StatusPackage'

//Timeline
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    BFormGroup,
    BForm,
    BFormSelect,
    BSidebar,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PackagesInCarrierBag,
    //Timeline
    AppTimeline,
    AppTimelineItem,
    StatusPackage,
  },
  setup() {
    const toast = useToast()
    //Data (properties)
    const loading = ref(true)
    const items = ref([])
    const tableColumns = ref([
      { key: 'tracking', label: 'Tracking', sortable: true },
      //   { key: 'client_package', label: 'Cliente', sortable: true },
      { key: 'description', label: 'Descripción', sortable: true },
      //   {
      //     key: 'large',
      //     label: 'Largo',
      //     sortable: true,
      //   },
      //   { key: 'height', label: 'Alto', sortable: true },
      //   { key: 'width', label: 'Ancho', sortable: true },
      //   { key: 'weight', label: 'Peso(lb)', sortable: true },
      //   { key: 'price', label: 'Valor', sortable: true },
      { key: 'last_package_status', label: 'Estado', sortable: true },

      { key: 'created_at', label: 'Fecha', sortable: true },
      { key: 'actions', label: 'Acciones' },
    ])

    const packagesFilters = ref([
      { text: 'Todos', value: 'all' },
      { text: 'Listos para saca', value: 'ready' },
      { text: 'Por identificar', value: 'pending' },
    ])

    const packageFilter = ref({ text: 'Todos', value: 'all' })

    //Pagination
    const currentPage = ref(1)
    const lastPage = ref(1)
    const totalRegisters = ref(1)
    const search = ref('')
    const fromRegister = ref(1)
    const toRegister = ref(perPageBaseRegisters)
    const perPage = ref(perPageBaseRegisters)
    const perPageOptions = ref(perPageBaseOptions)
    const clients = ref([])
    const showTimelinePackageModal = ref(false)
    //current package
    const currentPackage = ref(null)

    //Methods
    const dateFormat = (date, format = 'DD-MM-YYYY HH:mm A') => {
      return moment(date).format(format)
    }

    const showTimelinePackage = current_package => {
      showTimelinePackageModal.value = true
      console.log(current_package)
      currentPackage.value = current_package
    }

    const get = async (page = 1, searchQuery = {}) => {
      loading.value = true
      searchQuery.perPage = perPage.value
      searchQuery.filter = packageFilter.value.value
      const { data: res } = await orderService.getPackagesClient(
        page,
        searchQuery
      )
      items.value = res.data.data
      loading.value = false
      //Pagination
      lastPage.value = res.data.last_page
      toRegister.value = res.data.to
      fromRegister.value = res.data.from

      totalRegisters.value = res.data.total
    }

    //Ajax search select client
    const onSearch = async (search, loading) => {
      try {
        const { data: res } = await clientService.getClients(1, {
          perPage: 10,
          term: search,
        })

        clients.value = res.data.data
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Wachers
    watch(currentPage, (currentPage, prevCurrentPage) => {
      get(currentPage, {
        term: search.value,
      })
    })

    watch(perPage, currentPerPage => {
      toRegister.value = currentPerPage
      get(1, {
        term: search.value,
      })
    })

    watch(packageFilter, currentPackageFilter => {
      packageFilter.value = currentPackageFilter
      get(1, {
        term: search.value,
      })
    })

    watch(search, async (search, prevSearch) => {
      if (currentPage.value === 1) {
        await get(currentPage.value, {
          term: search,
        })
      } else {
        currentPage.value = 1
      }
    })

    //Mounted
    onMounted(() => {
      get()
    })

    const {
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolvePackageAvatarVariant,
    } = useInvoicesList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalRegisters,
      perPageOptions,
      search,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolvePackageAvatarVariant,
      items,
      fromRegister,
      toRegister,
      packagesFilters,
      packageFilter,
      onSearch,
      dateFormat,
      showTimelinePackageModal,
      showTimelinePackage,
      currentPackage,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
